import React, { useState, useEffect, useCallback } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import axios from 'axios'
import { useSessionContext, attemptRefreshingSession } from 'supertokens-auth-react/recipe/session'
import { useFeature } from '@growthbook/growthbook-react'
import { useErrorHandling } from './utils/ErrorHandling'
import { ConfigContext, getConfig } from './config'
import { FlowContainer } from './components/FlowContainer'
import { QuestionScreen } from './components/QuestionScreen'
import { KlarnaScreen } from './components/KlarnaScreen'
import { SuccessScreen } from './components/SuccessScreen'
import { sendDeclaration } from './components/DeclarationData'
import { AuthorizationScreen } from './components/AuthorizationScreen'
import useEventListener from './utils/useEventListener'
import { getUrlChapterNumber, getUrlScreenNumber } from './utils/getUrlData'
import { getPercentageIncrease } from './utils/getPercentageIncrease'
import { ConfirmationScreen } from './components/ConfirmationScreen'
import SuperTokensProvider from './components/SuperTokensProvider'
import CookiesService from './services/CookiesService'
import { ERROR_STATUS, EXCEPTIONS } from './exceptions'
import envConfig from './constants'

export function App() {
  const history = useHistory()
  const location = useLocation()
  const session = useSessionContext()
  const config = getConfig()
  const screenNumber = getUrlScreenNumber(location.pathname)
  const chapterNumber = getUrlChapterNumber(location.pathname)
  const [loading, setLoading] = useState(false)
  const [healthDeclarationData, setHealthDeclarationData] = useState([])
  const [appConfig, setAppConfig] = useState(config)
  const { chapters } = appConfig
  const screensLength = chapterNumber ? chapters[chapterNumber].screens.length : 0
  const [showOverviewOnMobile, setShowOverviewOnMobile] = useState(true)
  const [progress, setProgress] = useState(0)
  const [currentScreen, setCurrentScreen] = useState(screenNumber)
  const [currentChapter, setCurrentChapter] = useState(chapterNumber)
  const [errorCode, setErrorCode] = useState('')
  const [errorObject, setErrorObject] = useErrorHandling(errorCode)
  const [focused, setFocused] = useState(false)
  const [finished, setFinished] = useState(false)
  const [started, setStarted] = useState(null)
  const urlParams = new URLSearchParams(window.location.search)
  const onboardingId = urlParams.get('id')
  const payload = onboardingId && { id: onboardingId }
  const queryParams = (payload && new URLSearchParams(payload).toString()) || null
  const confirmationScreen = useFeature('confirmation-screen-variant').value || 0
  const [loadedDeclaration, setLoadedDeclaration] = useState(false)

  const features = {
    confirmationScreen,
  }

  const isDevEnv = process.env.REACT_APP_TEST === 'qa' || process.env.NODE_ENV === 'development'
  if (isDevEnv) {
    window.$chapters = chapters
  }

  const isQuestionScreenSubmittable = useCallback(screen => {
    const validatedAnswers = screen.data.answers.filter(answer => {
      if (answer.type === 'check_and_text') {
        return answer.inputValue !== '' && !!answer.selected
      }

      if (answer.type === 'check_more') {
        return false
      }

      if (answer.type === 'check_and_date') {
        return answer.inputValue !== '' && !!answer.selected
      }

      return answer.selected
    })

    const validateCheckAndText = screen.data.answers.filter(answer => {
      return answer.type === 'check_and_text' && answer.selected && answer.inputValue === ''
    })

    return validatedAnswers.length !== 0 && validateCheckAndText.length === 0
  }, [])

  const updateScreenData = useCallback(
    (data, chapterIndex, screenIndex) => {
      const updatedConfig = { ...appConfig }
      updatedConfig.chapters[chapterIndex].screens[screenIndex].data = { answers: [...data] }
      setAppConfig({
        ...updatedConfig,
      })
    },
    [appConfig]
  )

  const runSubmitAction = useCallback(
    async action => {
      switch (action) {
        case 'SEND_HEALTH_DECLARATION':
          console.log('SENDING HEALTH DECLARATION...')
          sendDeclaration(chapters, setErrorCode, EXCEPTIONS)
          return new Promise(resolve => setTimeout(resolve, 3000))

        case 'PROCESS_PAYMENT':
          console.log('PROCESSING PAYMENT...')
          return new Promise(resolve => setTimeout(resolve, 3000))

        default:
          break
      }
    },
    [chapters, setErrorCode]
  )

  const navigateToNextScreen = useCallback(
    async (submitAction, chapterIndex, screenIndex) => {
      if (submitAction) {
        setLoading(true)
        await runSubmitAction(submitAction)
        setLoading(false)
      }

      if (chapters[chapterIndex].screens.length - 1 > screenIndex) {
        setCurrentScreen(screenIndex + 1)
        return history.push(
          `/chapter${chapterIndex}/screen${screenIndex + 1}${
            chapterIndex === 0 && queryParams ? `?${queryParams}` : ''
          }`
        )
      }

      if (chapters[chapterIndex + 1] && chapters[chapterIndex + 1].screens) {
        setCurrentScreen(0)
        setCurrentChapter(chapterIndex + 1)

        return history.push(`/chapter${chapterIndex + 1}/screen0`)
      }

      setCurrentChapter(chapterIndex + 1)
    },
    [currentChapter, currentScreen, chapters, history, queryParams, runSubmitAction]
  )

  const navigateToPreviouslyScreen = useCallback(
    async (chapterIndex, screenIndex) => {
      if (chapters[chapterIndex].screens.length - 1 > screenIndex) {
        setCurrentScreen(screenIndex - 1)
        return history.push(`/chapter${chapterIndex}/screen${screenIndex - 1}`)
      }
    },
    [currentChapter, currentScreen, chapters, history]
  )

  const handlerStatusRedirect = useCallback(
    res => {
      if (location.pathname === '/' || chapterNumber > 0) {
        history.push(`/chapter0/screen0${queryParams ? `?${queryParams}` : ''}`)
      }

      if (res?.status === 'HealthDeclarationSubmitted' && res?.booking_fee === 0) {
        history.push(`/chapter0/screen0${queryParams ? `?${queryParams}` : ''}`)
        setErrorCode(EXCEPTIONS.NoSuchBooking)
      } else if (res?.status === 'HealthDeclarationSubmitted' && res?.booking_fee !== 0) {
        history.push(`/chapter3/screen0${queryParams ? `?${queryParams}` : ''}`)
      } else if (res?.status === 'PaymentRegistered') {
        history.push(`/chapter3/screen0${queryParams ? `?${queryParams}` : ''}`)
      } else if (res?.status === 'Confirmed') {
        history.push(`/chapter1/screen0${queryParams ? `?${queryParams}` : ''}`)
      } else if (ERROR_STATUS.includes(res?.status)) {
        setErrorCode(EXCEPTIONS.NoSuchBooking)
        history.push(`/chapter0/screen0${queryParams ? `?${queryParams}` : ''}`)
      } else {
        history.push(`/chapter1/screen0${queryParams ? `?${queryParams}` : ''}`)
      }
    },
    [queryParams, chapterNumber]
  )

  const fetchOnboardingStatus = useCallback(async query => {
    try {
      const { ONBOARDING_API_ENDPOINT } = envConfig

      const response = await axios.get(`${ONBOARDING_API_ENDPOINT}/${query ? `?${query}` : ''}`)
      setHealthDeclarationData(response.data)
      handlerStatusRedirect(response.data)

      return response.data
    } catch (e) {
      switch (e.response?.status) {
        case 400:
          setErrorCode(EXCEPTIONS.NoSuchBooking)
          break
        case 401:
          CookiesService.removeAllCookie()
          history.push(`/chapter0/screen0${query ? `?${query}` : ''}`)
          break
        case 403:
          setErrorCode(EXCEPTIONS.NoSuchBooking)
          break
        case 404:
          setErrorCode(EXCEPTIONS.NoSuchBooking)
          break
        case 500:
          setErrorCode(EXCEPTIONS.LostConnection)
          break
        default:
          break
      }
    }
  }, [])

  const initSession = useCallback(
    async query => {
      if (session.loading) return

      if (session.doesSessionExist) {
        await fetchOnboardingStatus(query).finally(() => setLoadedDeclaration(true))
      } else {
        handlerStatusRedirect()
        history.push(`/chapter0/screen0${queryParams ? `?${queryParams}` : ''}`)
        setLoadedDeclaration(true)
      }
    },
    [session, fetchOnboardingStatus, setErrorCode]
  )

  const handlerWindowMessage = useCallback(event => {
    switch (event.data?.action) {
      case 'started':
        setStarted(event.data?.data_bankid)
        if (event.data?.data_bankid?.redirect_needed && event.data?.data_bankid?.app_url) {
          window.location = event.data?.data_bankid?.app_url
        }
        break
      case 'in_progress':
        break
      case 'finished':
        CookiesService.removeCookie('st-last-access-token-update')
        attemptRefreshingSession()

        setFinished(true)
        break
      case 'failed':
        break
      default:
        break
    }
  }, [])

  useEffect(() => {
    setAppConfig(getConfig(healthDeclarationData, features))
  }, [healthDeclarationData])

  useEffect(() => {
    if (session.loading) return

    CookiesService.removeCookie('st-last-access-token-update')
    attemptRefreshingSession()
  }, [])

  useEffect(() => {
    initSession(queryParams)
  }, [session])

  useEffect(() => {
    const skipChapter = chapters[chapterNumber]?.skipChapter
    const skipScreen = chapters[chapterNumber]?.screens[screenNumber]?.skipScreen
    const updateProgress = () => {
      const allScreens = {}
      chapters.forEach((chapter, chapterIndex) =>
        chapter.screens.forEach((_, screenIndex) => {
          allScreens[`chapter-${chapterIndex}/screen-${screenIndex}`] = true
        })
      )

      const calculateProgress = () => getPercentageIncrease(screensLength, screenNumber)

      return setProgress(calculateProgress())
    }

    if (skipChapter || skipScreen) {
      navigateToNextScreen(undefined, chapterNumber, screenNumber)
    }

    updateProgress()
  }, [
    currentChapter,
    chapters,
    currentScreen,
    location.pathname,
    location,
    screensLength,
    screenNumber,
  ])

  useEventListener('message', handlerWindowMessage)

  return (
    <SuperTokensProvider declarationLoaded={loadedDeclaration}>
      <ConfigContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          ...appConfig,
          progress,
          currentScreen,
          currentChapter,
          healthDeclarationData,
          errorObject,
          errorCode,
          exceptions: EXCEPTIONS,
          updateScreenData,
          setCurrentChapter,
          setCurrentScreen,
          setProgress,
          setErrorCode,
          setErrorObject,
          focused,
          setFocused,
        }}
      >
        <FlowContainer
          showOverviewOnMobile={showOverviewOnMobile}
          onStepClick={() => setShowOverviewOnMobile(false)}
        >
          <TransitionGroup>
            <CSSTransition key={location.key} classNames="fade" timeout={300} unmountOnExit>
              <Switch location={location}>
                {chapters.map((chapter, chapterIndex) => {
                  if (chapter.skipChapter) return null

                  return chapter.screens.map((screen, screenIndex) => (
                    <Route
                      key={`chapter${chapterIndex}/screen${screenIndex}`}
                      path={`/chapter${chapterIndex}/screen${screenIndex}`}
                      exact
                    >
                      {(() => {
                        switch (screen.type) {
                          case 'authorization':
                            return (
                              <AuthorizationScreen
                                finished={finished}
                                started={started}
                                screenConfig={{
                                  progress,
                                  ...screen,
                                }}
                                onSubmit={submitAction =>
                                  navigateToNextScreen(submitAction, chapterIndex, screenIndex)
                                }
                              />
                            )

                          case 'payment':
                            return (
                              <KlarnaScreen
                                screenConfig={{
                                  progress,
                                  ...screen,
                                }}
                                onSubmit={submitAction =>
                                  navigateToNextScreen(submitAction, chapterIndex, screenIndex)
                                }
                              />
                            )

                          case 'question':
                            return (
                              <QuestionScreen
                                screenConfig={{
                                  progress,
                                  ...screen,
                                  submittable: isQuestionScreenSubmittable(screen),
                                  isLoading: loading,
                                  focused,
                                }}
                                onDataChange={data =>
                                  updateScreenData(data, chapterIndex, screenIndex)
                                }
                                onSubmit={submitAction =>
                                  navigateToNextScreen(submitAction, chapterIndex, screenIndex)
                                }
                                onBackClick={() =>
                                  navigateToPreviouslyScreen(chapterIndex, screenIndex)
                                }
                                screenIndex={screenNumber}
                                screensLength={screensLength}
                              />
                            )

                          case 'success':
                            return (
                              <SuccessScreen
                                {...screen}
                                screenIndex={screenNumber}
                                screensLength={screensLength}
                                progress={progress}
                                onSubmit={_ => navigateToNextScreen(_, chapterIndex, screenIndex)}
                              />
                            )

                          case 'confirmation':
                            return (
                              <ConfirmationScreen
                                {...screen}
                                screenIndex={screenNumber}
                                screensLength={screensLength}
                                progress={progress}
                                onSubmit={_ => navigateToNextScreen(_, chapterIndex, screenIndex)}
                                appointmentData={healthDeclarationData}
                              />
                            )

                          default:
                            break
                        }
                      })()}
                    </Route>
                  ))
                })}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </FlowContainer>
      </ConfigContext.Provider>
    </SuperTokensProvider>
  )
}
